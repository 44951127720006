import React from 'react';
import { Link } from 'react-router-dom'

const CancioneroItem = (props) => (
	<Link className="panel cancion"
	  to={{
	    pathname: `/lyrics/${props.slug}`,
	    state: { props }
	  }}
	>
	    <h2>{props.title}</h2>
		<p>{props.lyrics}</p>
	</Link>
)


export default CancioneroItem;