import React from 'react';
import { Link } from 'react-router-dom';
import ShowChords from './showchords'

const SongOptions = () => (
  <nav className="song-options">
    <Link to="/" className="option back">
        Volver
    </Link>
	<ShowChords />
    <div className="option audio">
    	Audio
    </div>
    <div className="option video">
    	Video
    </div>
  </nav>
)

export default SongOptions