import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
import Cancionero from './cancionero';
import Song from './song';
import './App.css';


function App() {
  return (
      <Router>
	    <div className="App">
	      <section>
	      	<Route exact path="/" component={Home}/>
	      	<Route exact path="/lyrics" component={Cancionero}/>
			<Route path='/lyrics/:song' component={Song}/>
	      </section>
	    </div>
    </Router>
  );
}


export default App;
