import React from 'react';

class ShowChords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return (
      <div onClick={this.handleClick} className="option chords">
        {this.state.isToggleOn ? 'ON' : 'OFF'}
        Acordes
      </div>
    );
  }
}

export default ShowChords