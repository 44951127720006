import React from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'
import AudioPlayer from "react-h5-audio-player";
import * as Markdown from 'react-markdown';
import Header from './header';
import SongOptions from './songoptions'


const Song = ({ location: { state: { props } }}) => {
	const ShowChords = false;

 	return (
	  <div>
	  	<Header />
		  <section className="single-title">
		  	<h1>{props.title}</h1>
		  	<div className="info-links">
		  		<span className="autor">{props.artist}</span>
		  		 &nbsp;·&nbsp;
		  		 <span className="cat">{props.tags}</span>
		  		 &nbsp;·&nbsp;
		  		 <span className="cat">{props.language}</span>
	  		</div>
		  </section>
		  <section className="panel lyrics">
            <Markdown source={props.lyrics} />
            <AudioPlayer src={props.audio} />
		    <div className='player-wrapper'>
	            <ReactPlayer 
	            	className="react-player"
	            	url={props.youTube} 
		          	width='100%'
		          	height='100%'
	          	/>
          	</div>
		  </section>

		  <SongOptions />
	  </div>
  )
}


export default Song