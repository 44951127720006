import React from 'react';
import logo from './logo-orange.png';
import logoBahaiSongs from './logo-bahaisongs-white-md.png';
import Search from './search';
import Nav from './nav';
import Cancionero from './cancionero';

const Home = () => (
  <div>
	  <header className="home-header">
	    <img src={logo} className="home logo" alt="logo" />
		<img src={logoBahaiSongs} className="bahaisongstext" alt="logo" />
	    <p id="slogan">Find and share your favourite bahai songs.<br/>
	    Sing along with friends at gatherings.</p>
	    <Search />
	    <Nav />
	  </header>

	  <Cancionero />
  </div>
)

export default Home;