import React from 'react';
import * as contentful from 'contentful'
import CancioneroItem from './cancioneroitem'


class Cancionero extends React.Component {
  state = {
    posts: []
  }


  client = contentful.createClient({
    space: 'aet28amxkk9o',
    accessToken: 'eOnPOe9x6jV_oIo1ExO3G2uG2mm7mdrZycxO2j1nmIA' 
  })

  
  componentDidMount() {
    this.fetchPosts().then(this.setPosts);
  }

  fetchPosts = () => this.client.getEntries()
  
  setPosts = response => {
    this.setState({
      posts: response.items
    })
  }

  render() {
    return (
      <div>
        <div className="playlist">
          { this.state.posts.map(({fields}, i) =>
            <CancioneroItem key={i} {...fields} />
          )}
        </div>
      </div>
    )
  }
}

export default Cancionero;