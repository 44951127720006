import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

function Search () {
	return(
	<div className="search">
        <input type="text" id="search" name="fname" placeholder="Search songs..." />
        <div className="info-links">
        	<Link to='#'>Categorías</Link> · <Link to="#">Autores</Link>
        </div>
    </div>
	)
}

export default Search;