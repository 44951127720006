import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

const Nav = () => (
  <nav>
    <Link to='/'>Start</Link>&nbsp;
    <Link to='/lyrics'>Lyrics</Link>
  </nav>
)

export default Nav;