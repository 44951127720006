import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import logo from './logo-orange.png';
import logoBahaiSongs from './logo-bahaisongs-white.png';


function Header ({ match }) {
	return (
	  <header className="app-header">
    	<Link to='/'>
		    <img src={logo} className="app logo" alt="logo" />
		    <img src={logoBahaiSongs} className="bahaisongstext" alt="logo" />
	    </Link>
	  </header>
	);
}

export default Header;